<template>
  <div class="performancePage">
    <StudioAdminTabs :list="tabs"></StudioAdminTabs>

    <div class="main_box" v-loading="listLoading">
      <div class="list_item_div" v-for="(item_1, index_1) in tableData" :key="index_1">
        <div class="list_item_year">{{ item_1.show_title }}</div>
        <div class="list_item_top">
          <div class="list_item_name">
            <div class="year_name">{{ item_1.title }}</div>
            <div v-if="item_1.tsa_status.status == 3" class="year_state_div">进行中</div>
            <div v-else class="year_state_div year_state_1">{{ item_1.tsa_status.status_str }}</div>
            <div class="option_btn">
              <div class="option_box_btn option_box_btn2" v-if="item_1.examine.examine >= 1" @click="upDaochu(item_1)"
                type="success">材料导出</div>
              <div class="option_box_btn" type="primary" @click="handleToInfo(item_1)">查看详情</div>
            </div>
          </div>
          <div class="msg_info_div">
            <span class="msg_info_item">考核周期：{{ item_1.show_title }}</span>
            <span class="msg_info_item">考核对象：{{ item_1.host_type_str }}</span>
            <span class="msg_info_item" style="margin-right: 0px">考核开展时间：{{ dateTime(item_1.start_time_stamp) }} 至
              {{ dateTime(item_1.end_time_stamp) }}</span>
          </div>
        </div>

        <div class="list_item_jindu">

          <div v-if="item_1.steps" class="step_div_box">
            <JxStepBar :stepArr="item_1.steps" :currentIndex="item_1.current_step"></JxStepBar>
          </div>

          <div class="res_end_div">
            <!-- <div class="res_end_box">优秀</div> -->
            <!-- <div class="res_end_span">最终评级</div> -->
          </div>

        </div>

      </div>
      <div class="block" v-show="ListTotal">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="form.page" :page-size="form.limit" layout="prev, pager, next, jumper" :total="ListTotal">
        </el-pagination>
      </div>
      <CommonEmpty v-if="!listLoading && !tableData.length" hVal="200px"></CommonEmpty>
    </div>
  </div>
</template>

<script>
import JoinStudio from "@/components/joinStudio/index.vue";
import * as researchApi from '@/api/researchApi.js';
import { deepClone, isObject } from "@/utils/utils.js";
import JxStepBar from "./jx_step_bar.vue";

export default {
  // name: "",
  data() {
    return {
      teaching_studio_id: "",
      detailData: {},

      listLoading: true, //表格Loading
      tableData: [],
      ListTotal: 0,
      form: {
        page: 1,
        limit: 10,
      },
      //导航
      tabs: [
        {
          label: "工作室绩效考核",
          count: 0
        },
      ],
    }
  },
  computed: {},

  components: {
    JxStepBar
  },

  methods: {
    handleSizeChange(val) {
      this.form.limit = val;
      this.get_my_list();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.get_my_list();
    },
    upDaochu(item) {
      // window.open('https://dckj.ks3-cn-guangzhou.ksyun.com/test/2023-08-15/9e13edf2-e722-4e54-a8e2-ecdcd2fbf9a8.zip')
      // return
      let tempOBj = {};
      tempOBj['teaching_studio_appraisal_id'] = item.teaching_studio_appraisal_id;
      tempOBj['teaching_studio_id'] = this.teaching_studio_id;
      this.$message.success("处理中，请勿刷新页面~")
      researchApi.jx_api_export_appraisal(tempOBj).then(res => {
        researchApi.jx_api_logs_filesList(tempOBj).then(ress => {
          if (ress.data.data.data.length == 0) return
          window.open(ress.data.data.data[0].url)
        }).catch(err => {
        })
      }).catch(err => {
      })
    },
    dateTime(now) {
      var date = new Date(now * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '/';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      return Y + M + D;
    },
    // 获取横幅
    get_my_list() {
      let params = {
        teaching_studio_id: this.teaching_studio_id,
        page: this.form.page,
        limit: this.form.limit,
      };
      this.listLoading = true;
      researchApi.jx_api_TeachingStudioAppraisal_get_lists(params).then(res => {
        let resData = res.data.data;
        resData.data.forEach(item_1 => {
          item_1.current_step = 0;
          if (isObject(item_1.steps)) {
            let tempArr = [];
            Object.values(item_1.steps).forEach(v => { tempArr.push(v); });
            item_1.steps = [];
            item_1.steps = tempArr;
            item_1.steps.forEach((item_2, index_2) => {
              if (isObject(item_1.examine) && (item_2.examine == item_1.examine.examine)) {
                item_1.current_step = index_2 + 1;
              };
              if (item_1.examine == 6 && item_1.step_status == 2) {
                this.current_step = 10;
              };
            });
          };
        });
        this.tableData = resData.data;
        this.ListTotal = resData.total;
        this.$nextTick(() => {
          this.listLoading = false;
        });
      }).catch(err => {
        this.tableData = [];
        this.ListTotal = 0;
        this.$nextTick(() => {
          this.listLoading = false;
        });
      })
    },
    // 查看详情
    handleToInfo(row) {
      this.$router.push({
        path: "/performanceDetails",
        query: {
          teaching_studio_appraisal_id: row.teaching_studio_appraisal_id,
          teaching_studio_id: this.teaching_studio_id,
        },
      });
    }
  },

  destroyed() {
  },

  mounted() {
    let query = this.$route.query;
    if (query && (query.teaching_studio_id || query.studioId)) {
      this.teaching_studio_id = query.teaching_studio_id || query.studioId;
      localStorage.setItem("studioId", this.teaching_studio_id);
    } else {
      this.teaching_studio_id = localStorage.getItem("studioId");
    };
    this.get_my_list();
  },

  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.performancePage {
  width: 100%;
  min-height: 1107px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
}

.title_page {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 26px;
  border-bottom: 1px solid #f0f0f0;

  span {
    font-size: 16px;
    font-weight: 700;
    color: #333333;
  }
}

.main_box {
  width: 100%;
  padding: 20px;
}

.list_item_div {
  width: 100%;
  background: rgba(244, 247, 255, 1);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;

  .list_item_year {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 10px;
    padding-right: 10px;
    height: 26px;
    background: rgba(255, 144, 0, 1);
    border-radius: 8px 0px 8px 0px;
    font-size: 18px;
    font-family: DIN;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    line-height: 26px;
  }

  .list_item_top {
    width: 100%;
    height: 140px;
    background: rgba(224, 238, 255, 1);
    padding-left: 30px;
    padding-right: 30px;

    .list_item_name {
      display: flex;
      align-items: center;
      padding-top: 46px;

      .year_name {
        font-size: 18px;
        color: rgba(0, 0, 0, 1);
        margin-right: 10px;
      }

      .year_state_div {
        // width: 50px;
        padding: 0 10px;
        height: 24px;
        background: rgba(67, 179, 109, 1);
        border-radius: 12px 12px 0 13px;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
      }

      .year_state_1 {
        background-color: #7a838e;
      }

      .option_btn {
        margin-left: auto;
        display: flex;
      }

      .option_box_btn {
        width: 100px;
        height: 36px;
        line-height: 36px;
        background: rgba(52, 137, 254, 1);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: rgba(255, 255, 255, 1);
        margin-right: 0px;
        margin-left: 20px;
        cursor: pointer;
      }

      .option_box_btn2 {
        background: #43b36d;
      }
    }

    .msg_info_div {
      margin-top: 14px;
      align-items: center;
      display: flex;
      width: 100%;

      .msg_info_item {
        margin-right: 110px;
        font-size: 14px;
        color: rgba(102, 102, 102, 1);
      }
    }
  }
}

.list_item_jindu {
  width: 100%;
  min-height: 140px;
  padding-left: 10px;
  padding-right: 30px;
  display: flex;
  padding-top: 14px;
}

.res_end_div {
  width: 170px;
  flex: 0 0 170px;

  .res_end_box {
    width: 100%;
    height: 50px;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    font-size: 24px;
    font-weight: bold;
    color: rgba(255, 54, 54, 1);
    text-align: center;
    line-height: 50px;
  }

  .res_end_span {
    margin-top: 18px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 1;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
  }
}

.step_div_box {
  flex: 1;
}

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
</style>